import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  styled,
  SwipeableDrawer,
  TextField,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { db, functions } from "../../firebase.config";
import CustomButton from "../CustomButton";
import EventCard from "../EventCard";
import Iconify from "../Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../../context/VariableContext";
import { httpsCallable } from "firebase/functions";

const SwipeD = ({ open, count }) => {
  const { user, logIn, signUpSecondStep, signUp, forgotPass,handleFacebookSigin, faceSecondStep, handleGoogleSigin, googleSecondStep } = UserAuth();
  const { openLogin, setOpenLogin } = Variables();
  const [loading, setLoading] = React.useState(false);
  const [cloading, setCLoading] = React.useState(false);
  const [iloading, setILoading] = React.useState(false);
  const [floading, setFLoading] = React.useState(false);
  const [gloading, setGLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [countTmp, setCount] = React.useState(0);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  React.useEffect(() => {
    (async () => {
      try {
        console.log(open);
        console.log(count);
        console.log(countTmp);
        if (user) {
          setOpenConnexion(false);
        } else {
          if (open) {
            setOpenConnexion(false);
          } else if (!open && count !== 0) {
            if (countTmp === count) {
              setOpenConnexion(false);
            } else {
              setOpenConnexion(true);
              setCount(count);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        console.log('-------------------user change-----------------------')
        console.log(user)
        // const addMessage = httpsCallable(functions, "generateTicket");
        // const result = await addMessage({ confirm: confirmID });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [user]);

  // champs
  //connexion section
  const [emailField, setEmail] = useState("");
  const [helpText, setHelp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [passwordField, setPassword] = useState("");
  const [nomField, setNom] = useState("");
  const [prenomField, setPrenom] = useState("");
  const [confirmPassdField, setPasswordConfirm] = useState("");
  const [passError, setPassError] = useState(false);
 

  const toggleDrawerConnexion = (open) => (event) => {
    setOpenLogin(open);
  };

  // connexion function
  const seConnecter = async () => {
    setLoading(true)
    try {
      setPassError(false);
      setEmailError(false);
      console.log(emailField);
      console.log(passwordField);
      if (emailField === "" || passwordField === "") {
        if (emailField === "") {
          setEmailError(true);
        }
        if (passwordField === "") {
          setPassError(true);
        }
        setError(true);
        setErrorMsg("email et mot de passe requis pour la connexion");
        return;
      }
      const u = await logIn(emailField, passwordField);
      const u1 = await getDoc(doc(db, "users", u.user.uid));
      if (!u1.exists()) {
        await signUpSecondStep(u.user.uid);
      }
      setOpenLogin(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMsg("Email ou mot de passe inconue");
    }
    setLoading(false)
  };

  // connexion face
  const faceSign = async () => {
    setFLoading(true)
    try {
      const u = await handleFacebookSigin();
      
      setOpenLogin(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMsg("Email ou mot de passe inconue");
    }
    setFLoading(false)
  };

    // connexion face
    const googleSign = async () => {
      setFLoading(true)
      try {
        const u = await handleGoogleSigin();
        console.log('------------------------------');
        console.log(user);
        setOpenLogin(false);
      } catch (error) {
        console.log(error);
        setError(true);
        setErrorMsg("Email ou mot de passe inconue");
      }
      setFLoading(false)
    };

  // inscription function
  const inscription = async (e) => {
    try {
      setILoading(true)
      setPassError(false);
      setEmailError(false);
      setError(false)
      console.log(emailField);
      console.log(passwordField);
      if (nomField === "" || prenomField === "" || emailField === "" || passwordField === "" ) {
        setError(true);
        setHelp("Ce champ est obligatoire");
        setErrorMsg("Des erreurs sont survenues avec les informations fournies.");
        return;
      }
      if(passwordField !== confirmPassdField){
        setError(true);
        setErrorMsg("Des erreurs sont survenues avec les informations fournies.");
        return;
      }
      const reg = await signUp(emailField, passwordField);
      // create organisation
      console.log(reg.user.uid);
      const docRef = await setDoc(doc(db, "users", reg.user.uid), {
        dateCreation: serverTimestamp(),
        email: emailField,
        nom: nomField,
        prenom: prenomField,
        uid: reg.user.uid,
      });
      setOpenLogin(false);
    } catch (error) {
      console.log("error");
      setError(true);
      setErrorMsg("Des erreurs sont survenues avec les informations fournies.");
      console.log(error.message);
    }
    setILoading(false)
    // navigate('/bo', { replace: true });
  };

  // drawer
  // drawer connection
  const [openConnexion, setOpenConnexion] = React.useState(open);
  const [connexion, setConnexion] = React.useState(0);

  //handleInscription
  const handleInscription = (e) => {
    setError(false);
    setErrorMsg();
    setConnexion(e);
  };

  const listField = (anchor) => (
    <Box sx={{ width: { xs: "100%", sm: 650 } }} role="presentation">
      <IconButton
        aria-label="delete"
        onClick={() => {
          setOpenLogin(false);
        }}
      >
        <Iconify
          icon="solar:close-circle-linear"
          sx={{
            width: 24,
            height: 24,
            alignItems: "center",
          }}
        />
      </IconButton>
      {connexion === 0 ? (
        
        <Grid
          padding={{ xs: 3, sm: 5 }}
          container
          spacing={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12}>
            <Typography
              fontSize={30}
              sx={{
                color: "#141414",
                fontWeight: "800",
              }}
            >
              Se connecter
            </Typography>
          </Grid>
          {error ? (
            <Typography
              textAlign="end"
              fontSize={18}
              color={"red"}
              paddingY={1}
            >
              {errorMsg}
            </Typography>
          ) : null}

          <Grid item xs={12} md={12}>
            <TextField
              value={emailField}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              label="Adresse email"
              error={emailError}
              helperText={ emailError ? "Veuillez saisir une adresse mail valide" : null}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={passwordField}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              type="password"
              label="Mot de passe"
              error={passError}
              helperText={ passError ? "Un mot de passe est nécessare" : null}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="end"
              fontSize={18}
              paddingY={1}
              sx={{ cursor: "pointer" }}
              onClick={() => handleInscription(2)}
            >
              Mot de passe oublié ?
            </Typography>
            <Button
              size="large"
              fullWidth
              sx={{
                textTransform: "initial",
                border: "2px solid transparent",
                color: "white",
                backgroundColor: "#5E17EB",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#5E17EB",
                  borderColor: "#5E17EB",
                },
              }}
              variant="contained"
              onClick={() => {
                seConnecter();
              }}
            >
              {cloading ? <CircularProgress /> : "Se connecter"}
            </Button>

            <Typography
              fontSize={16}
              textAlign="center"
              paddingTop={3}
              paddingBottom={1}
            >
              Pas encore de compte Choptonticket ?
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button
                size="large"
                fullWidth
                sx={{
                  textTransform: "initial",
                  border: "2px solid transparent",
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "7px",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    borderColor: "black",
                  },
                }}
                variant="contained"
                onClick={() => {
                  handleInscription(1);
                }}
              >
                {iloading ? <CircularProgress /> : "S'inscrire"}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' justifyContent="space-evenly" alignItems="center">
                <Box sx={{width:"100%"}}>
                  <Divider sx={{color:'black'}}/>
                </Box>
                
                <Typography paddingX={1}>ou</Typography>
                <Box sx={{width:"100%"}}>
                  <Divider sx={{color:'black'}}/>
                </Box>
            </Stack>
            <Button
              startIcon={<Facebook/>}
              size="large"
              fullWidth
              sx={{
                textTransform: "initial",
                border: "2px solid black",
                color: "black",
                backgroundColor: "white",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              variant="contained"
              onClick={() => {
                faceSign()
              }}
            >
              {floading ? <CircularProgress /> : "Continuer avec facebook"}
            </Button>
            <Button
              
              startIcon={<Google/>}
              size="large"
              fullWidth
              sx={{
                marginTop:3,
                textTransform: "initial",
                border: "2px solid black",
                color: "black",
                backgroundColor: "white",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              variant="contained"
              onClick={() => {
                googleSign();
              }}
            >
              {gloading ? <CircularProgress /> : "Continuer avec google"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14}
              textAlign="center"
              paddingTop={1}
              paddingBottom={1}>
              En continuant, tu acceptes les conditions d'utilisation de
              Choptonticket
            </Typography>
          </Grid>
        </Grid>
      ) : connexion === 1 ? (
        <Grid
          padding={{ xs: 3, sm: 5 }}
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} md={12}>
            <Typography
              fontSize={30}
              sx={{
                color: "#141414",
                fontWeight: "800",
              }}
            >
              Inscription
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={nomField}
              onChange={(e) => setNom(e.target.value)}
              required
              fullWidth
              label="Nom"
              helperText={error && nomField === "" && helpText}
              error={error && nomField === ""}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={prenomField}
              onChange={(e) => setPrenom(e.target.value)}
              required
              fullWidth
              label="Prenoms"
              helperText={error && prenomField === "" && helpText}
              error={error && prenomField === ""}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
            type='email'
              value={emailField}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              label="Adresse email"
              helperText={error && emailField === "" && helpText}
              error={error && emailField === ""}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='password'
              value={passwordField}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              label="Mot de passe"
              helperText={error && passwordField === "" && helpText}
              error={error && passwordField === ""}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='password'
              value={confirmPassdField}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              required
              fullWidth
              label="Confirmer le mot de passe"
              helperText={error && confirmPassdField !== passwordField && 'Les mots de passe saisi ne sont pas identique'}
              error={error && confirmPassdField !== passwordField}
            >
              {" "}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              fullWidth
              sx={{
                textTransform: "initial",
                border: "2px solid transparent",
                color: "white",
                backgroundColor: "#5E17EB",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#5E17EB",
                  borderColor: "#5E17EB",
                },
              }}
              variant="contained"
              onClick={() => {
                inscription();
              }}
            >
              {iloading ? <CircularProgress /> : "S'inscrire"}
            </Button>
            <Typography
              fontSize={16}
              textAlign="center"
              paddingTop={3}
              paddingBottom={1}
            >
              Tu as déjà un compte ?
            </Typography>
            <Button
              size="large"
              fullWidth
              sx={{
                textTransform: "initial",
                border: "2px solid transparent",
                color: "white",
                backgroundColor: "black",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "blacb",
                  borderColor: "black",
                },
              }}
              variant="contained"
              onClick={() => {
                handleInscription(0);
              }}
            >
              {cloading ? <CircularProgress /> : "Se connecter"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' justifyContent="space-evenly" alignItems="center">
                <Box sx={{width:"100%"}}>
                  <Divider sx={{color:'black'}}/>
                </Box>
                
                <Typography paddingX={1}>ou</Typography>
                <Box sx={{width:"100%"}}>
                  <Divider sx={{color:'black'}}/>
                </Box>
            </Stack>
            <Button
              startIcon={<Facebook/>}
              size="large"
              fullWidth
              sx={{
                textTransform: "initial",
                border: "2px solid black",
                color: "black",
                backgroundColor: "white",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              variant="contained"
              onClick={() => {
                faceSign()
              }}
            >
              {floading ? <CircularProgress /> : "Continuer avec facebook"}
            </Button>
            <Button
              
              startIcon={<Google/>}
              size="large"
              fullWidth
              sx={{
                marginTop:3,
                textTransform: "initial",
                border: "2px solid black",
                color: "black",
                backgroundColor: "white",
                borderRadius: "7px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                },
              }}
              variant="contained"
              onClick={() => {
                googleSign();
              }}
            >
              {gloading ? <CircularProgress /> : "Continuer avec google"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14}
              textAlign="center"
              paddingTop={1}
              paddingBottom={1}>
              En continuant, tu acceptes les conditions d'utilisation de
              Choptonticket
            </Typography>
          </Grid>
        </Grid>
      ) : connexion === 2 ? (
        <Grid
          padding={{ xs: 3, sm: 5 }}
          container
          spacing={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12}>
            <Typography
              fontSize={30}
              sx={{
                color: "#141414",
                fontWeight: "800",
              }}
              paddingTop={5}
              paddingBottom={2}
            >
              Connexion
            </Typography>
            <Typography fontSize={18} fontWeight={600} paddingTop={1}>
              Mot de passe oublié ?
            </Typography>
            <Typography fontSize={18} paddingBottom={1}>
              Pas de panique, on t’envoie le lien pour réinitialiser ton mot de
              passe!
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              value={emailField}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              label="Adresse email"
            >
              {" "}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <CustomButton
              backgroundColor="#0F1B4C"
              color="#fff"
              buttonText="Envoyer le lien par Email"
              onClick={ async () => {
                await forgotPass('beschristyann@hotmail.com')
                console.log("mot de passe oublié ...");
              }}
              fullWidth
            />
            <Typography
              fontSize={16}
              textAlign="center"
              paddingTop={5}
              onClick={() => handleInscription(0)}
            >
              Revenir en arrière
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14}>
              En continuant, tu acceptes les conditions d'utilisation de
              Choptonticket
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );

  return (
    <Box sx={{ mt: 5 }}>
      <SwipeableDrawer
        anchor="right"
        open={openLogin}
        onClose={toggleDrawerConnexion(false)}
        onOpen={toggleDrawerConnexion(true)}
      >
        {listField("right")}
      </SwipeableDrawer>
    </Box>
  );
};

export default SwipeD;
