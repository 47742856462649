import {
  Box,
  Button,
  CardMedia,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";

import heroImg from "../media/hero_illustration.png";
import CustomButton from "./CustomButton";

import Typewriter from "typewriter-effect";
import heroImage from "../media/hero.jpg";
import banner from "../media/banner.png";
import useResponsive from "./hooks/useResponsive";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";

const Hero = () => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(5),
    marginTop: theme.spacing(7),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  return (
    <Box>
      {/*<Navbar />*/}
      <Box sx={{ paddingY: { xs: 10, sm: 10 }, minHeight: "50vh" }}>
        <Container>
          <CustomBox>
            <Box
              sx={{
                bgcolor: "black",
                position: "absolute",
                width: "100%",
                left: 0,
                zIndex: -1,
                filter: { xs: "blur(6rem)", sm: "blur(10rem)" },
              }}
            >
              {/** */}
              <CardMedia
                component="img"
                sx={{ width: "100%", objectFit: "cover" }}
                height="400"
                image={heroImage}
                title="green iguana"
              />
              <Box
                sx={{
                  bgcolor: "black",
                  width: "100%",
                  height: "400",
                }}
              />
            </Box>
            <Box sx={{ flex: "1" }}>
              <Typography
                sx={{ color: "white", fontSize: "40px", fontWeight: "800" }}
              >
                Prêt à vivre des expériences incroyables ?
              </Typography>
              <Typography
                paddingTop={4}
                variant="body2"
                sx={{
                  fontSize: "18px",
                  color: "white",
                  fontWeight: "500",
                }}
              >
                Trouvez les billets que vous cherchez en quelques clics sur
                notre plateforme.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "18px", color: "white", mt: 2, mb: 4 }}
              >
                100% sécurisé et 100% fiable
              </Typography>

              <Stack direction="row" spacing={2} justifyContent="center">
                <img
                  src={androidBadge}
                  alt="illustration"
                  style={{ maxWidth: 125 }}
                />
                <img style={{ Width: 150 }} src={iosBadge} alt="Logo" />
              </Stack>
            </Box>
            {/*
              smUp &&  <Box sx={{ flex: "1.25", borderRadius: "7px" }}>
              <img
                src={heroImage}
                alt="heroImg"
                style={{
                  maxWidth: "100%",
                  marginBottom: "2rem",
                  borderRadius: "10px",
                }}
              />
            </Box>
              */}
          </CustomBox>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
