import React, { createContext, useContext, useState, useEffect } from "react";
import {
  setDoc,
  doc,
  collection,
  Timestamp,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithPopup,
} from "firebase/auth";
import { auth, db, sp, spGoogle } from "../firebase.config";
import { Snackbar } from "@mui/material";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  
  const [user, setUser] = useState({});
  const [event, setCurrentEvent] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function  signUpSecondStep(id) {
    const u = await getDoc(doc(db, "organisateurs", id));
    if (u.exists()) {
      const newCityRef = doc(db, "users", id);
      setDoc(newCityRef, {
        dateCreation: u.data().dateCreation,
        email: u.data().email,
        nom: u.data().nom,
        prenom: u.data().prenom,
        uid: id
      });
    }else{
        logOut()
    }
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  function forgotPass(email) {
    return sendPasswordResetEmail(auth, email)
  }

  async function handleFacebookSigin(){
    signInWithPopup(auth, sp).then(async (result) =>{
      console.log(result)
      const u = result.user
      const u1 = await getDoc(doc(db, "users", u.uid));
      if (!u1.exists()) {
        console.log('creation')
        var tmpName = u.displayName.split(" ")
        console.log(tmpName)
        console.log(tmpName.length)
        await faceSecondStep(u.uid,u.email,tmpName[0],tmpName[tmpName.length-1],u.photoURL);
      }
      return result.user
    }).catch((error) =>{
      console.log(error.message)
    })
  }
  async function  faceSecondStep(id, mail, firstname, lastname,photo) {
    try {
      const newCityRef = doc(db, "users", id);
      setDoc(newCityRef, {
        dateCreation: serverTimestamp(),
        email: mail,
        nom: lastname,
        prenom: firstname,
        uid: id,
        photoUrl: photo
      });
    } catch (error) {
      console.log(error.message)
    }
      
  }

  // Google ----------------------------------------------

  async function handleGoogleSigin(){
    signInWithPopup(auth, spGoogle).then(async (result) =>{
      console.log(result)
      const u = result.user
      const u1 = await getDoc(doc(db, "users", u.uid));
      if (!u1.exists()) {
        console.log('creation')
        var tmpName = u.displayName.split(" ")
        console.log(tmpName)
        console.log(tmpName.length)
        await googleSecondStep(u.uid,u.email,tmpName[0],tmpName[tmpName.length-1],u.photoURL);
      }
      return u1
    }).catch((error) =>{
      console.log(error.message) 
    })
  }
  async function  googleSecondStep(id, mail, firstname, lastname,photo) {
    try {
      const newCityRef = doc(db, "users", id);
      setDoc(newCityRef, {
        dateCreation: serverTimestamp(),
        email: mail,
        nom: lastname,
        prenom: firstname,
        uid: id,
        photoUrl: photo
      });
    } catch (error) {
      console.log(error.message)
    }
      
  }

  // -----------------------------------------------------

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  });

  return (
    <AuthContext.Provider
      value={{ signUp, logIn, logOut, signUpSecondStep, forgotPass,handleFacebookSigin, faceSecondStep, handleGoogleSigin, googleSecondStep, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => useContext(AuthContext);
